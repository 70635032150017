import { useSelector, useDispatch } from 'react-redux'
import { setUser, initialState } from 'store/auth/userSlice'
import { onSignInSuccess, onSignOutSuccess } from 'store/auth/sessionSlice'
import appConfig from 'configs/app.config'
import { REDIRECT_URL_KEY } from 'constants/app.constant'
import { useNavigate } from 'react-router-dom'
import useQuery from './useQuery'

function useAuth() {
    const dispatch = useDispatch()

    const navigate = useNavigate()

    const query = useQuery()

    const { token, signedIn } = useSelector((state) => state.auth.session)

    const signIn = async (values) => {   
        console.log(values);
        const {id, email, token, vendors } = values;         
        const user = {
            id,
            avatar: '',
            userName: email,
            email: email,
            authority: [],
            accessToken: token,
            customerName: vendors[0].company_name,
            vendorName: vendors[0].name,
            vendorId: vendors[0].id,
            customerCode: vendors[0].customer_code,
        };
        dispatch(onSignInSuccess(token))
        dispatch(setUser(user))
        const redirectUrl = query.get(REDIRECT_URL_KEY)
        navigate(
            redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
        )
    }

    const handleSignOut = () => {
        dispatch(onSignOutSuccess())
        dispatch(setUser(initialState))
        navigate(appConfig.unAuthenticatedEntryPath)
    }

    const signOut = async () => {
        handleSignOut()
    }

    return {
        authenticated: token && signedIn,
        signIn,
        signOut,
    }
}

export default useAuth
