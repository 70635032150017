import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    id: null,
    avatar: '',
    userName: '',
    email: '',
    authority: [],
    accessToken: '',
    customerName: '',
    customerCode: '',
    vendorName: '',
    vendorId: null,
}

export const userSlice = createSlice({
    name: 'auth/user',
    initialState,
    reducers: {
        setUser: (_, action) => action.payload,
        setEmail: (state, action) => {
            state.email = action.payload
        },
        updateUser: (state, action) => {
            return {
                ...state,
                ...action.payload
            };
        },
        userLoggedOut: () => initialState,
    },
})

export const { setUser, setEmail, userLoggedOut, updateUser } = userSlice.actions

export default userSlice.reducer
