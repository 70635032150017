const appConfig = {
    apiPrefix: process.env.REACT_APP_VENDOR_API,
    authenticatedEntryPath: '/access-record',
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '/',
    locale: 'en',
    enableMock: false,
    appUrl: process.env.REACT_APP_URL,
    apiUrl: process.env.REACT_APP_API_URL,
}

export default appConfig
